export function Info(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 15.4075L15.0547 15.3808C15.2256 15.2954 15.4175 15.2608 15.6076 15.281C15.7976 15.3013 15.9779 15.3756 16.127 15.4951C16.2761 15.6146 16.3879 15.7744 16.449 15.9555C16.5102 16.1365 16.5181 16.3313 16.472 16.5168L15.528 20.2981C15.4815 20.4837 15.4893 20.6787 15.5503 20.86C15.6113 21.0413 15.723 21.2013 15.8722 21.321C16.0214 21.4407 16.2018 21.5152 16.392 21.5354C16.5822 21.5557 16.7742 21.521 16.9453 21.4355L17 21.4075M28 16.4075C28 17.9833 27.6896 19.5438 27.0866 20.9997C26.4835 22.4556 25.5996 23.7784 24.4853 24.8928C23.371 26.0071 22.0481 26.891 20.5922 27.494C19.1363 28.0971 17.5759 28.4075 16 28.4075C14.4241 28.4075 12.8637 28.0971 11.4078 27.494C9.95189 26.891 8.62902 26.0071 7.51472 24.8928C6.40042 23.7784 5.5165 22.4556 4.91345 20.9997C4.31039 19.5438 4 17.9833 4 16.4075C4 13.2249 5.26428 10.1726 7.51472 7.92219C9.76516 5.67175 12.8174 4.40747 16 4.40747C19.1826 4.40747 22.2348 5.67175 24.4853 7.92219C26.7357 10.1726 28 13.2249 28 16.4075ZM16 11.4075H16.0107V11.4181H16V11.4075Z"
        stroke="currentColor"
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

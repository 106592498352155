export function Close(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.947 16.008a.75.75 0 0 0 1.061-1.06l-3.333-3.334 3.333-3.334a.75.75 0 1 0-1.06-1.06l-3.334 3.333L8.28 7.22a.75.75 0 0 0-1.06 1.06l3.333 3.334-3.333 3.333a.75.75 0 0 0 1.06 1.061l3.334-3.333 3.333 3.333Z"
        stroke="currentColor"
      />
    </svg>
  );
}

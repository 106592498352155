export function LeerIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={42}
      height={35}
      viewBox="0 0 42 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        clipPath="url(#clip0_298_1681)"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M6.666 34.19H4.66C2.638 34.19 1 32.54 1 30.502V4.688C1 2.65 2.638 1 4.66 1h2.006c2.022 0 3.66 1.65 3.66 3.688v25.814c0 2.038-1.638 3.688-3.66 3.688z" />
        <path d="M15.99 34.19h-2.006c-2.022 0-3.66-1.65-3.66-3.688V4.688C10.324 2.65 11.962 1 13.984 1h2.006c2.022 0 3.66 1.65 3.66 3.688v25.814c0 2.038-1.638 3.688-3.66 3.688zM32.907 21.406l-.045-7.624M29.08 17.618l7.565-.045" />
        <path d="M33.62 25.323a7.194 7.194 0 01-.442.014c-4.244 0-7.683-3.466-7.683-7.741a7.741 7.741 0 014.86-7.2l-1.402-6.415c-.435-1.99-2.387-3.246-4.362-2.808l-1.96.435c-1.974.439-3.22 2.406-2.785 4.395l5.515 25.21c.435 1.989 2.387 3.245 4.362 2.806l1.96-.434c1.974-.439 3.22-2.406 2.785-4.396l-.846-3.864-.003-.002z" />
        <path d="M32.862 25.022c4.083 0 7.393-3.336 7.393-7.45 0-4.115-3.31-7.45-7.393-7.45s-7.394 3.335-7.394 7.45c0 4.114 3.31 7.45 7.394 7.45z" />
      </g>
      <defs>
        <clipPath id="clip0_298_1681">
          <path fill="#fff" d="M0 0H42V35H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function Building(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.9191 24.9242V15.0137C24.9191 14.7509 24.8147 14.4988 24.6288 14.313C24.443 14.1271 24.1909 14.0227 23.928 14.0227H19.9639"
        stroke="currentColor"
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.08008 24.9239V9.06722C7.08008 8.80437 7.18449 8.5523 7.37035 8.36644C7.5562 8.18059 7.80828 8.07617 8.07112 8.07617H18.9726C19.2354 8.07617 19.4875 8.18059 19.6734 8.36644C19.8592 8.5523 19.9636 8.80437 19.9636 9.06722V24.9239"
        stroke="currentColor"
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7969 12.2644H16.2477"
        stroke="currentColor"
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7969 16.5H16.2477"
        stroke="currentColor"
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7963 24.9238H16.2471V20.6572H10.7963V24.9238Z"
        stroke="currentColor"
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.9097 24.9238H6.08887"
        stroke="currentColor"
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function LogoSkeelo(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.973 16.374a4.302 4.302 0 0 0-2.259-3.262l.001-.002A6.954 6.954 0 0 1 1 6.956 6.96 6.96 0 0 1 8.676.036a6.958 6.958 0 0 1 4.843 2.725l.005.005a2.659 2.659 0 0 0 .741 4.83l-.002.005a6.96 6.96 0 0 1 4.17 10.436 6.984 6.984 0 0 1-.912 1.157v-.001a2.659 2.659 0 0 1-4.57-1.85c0-1.018.573-1.902 1.415-2.348l-.002-.002a2.656 2.656 0 0 0 1.404-2.601 4.3 4.3 0 0 0-3.5-1.797c-.833 0-1.61.236-2.27.645a2.65 2.65 0 0 0-1.654-.644 2.658 2.658 0 0 0 .834 2.666l-.001.08c0 .671.25 1.284.66 1.752v.001a1.643 1.643 0 1 1-2.865 1.279Zm5.18-3.302a1.64 1.64 0 0 1-.794-.204 1.651 1.651 0 0 1 2.257-.685 1.65 1.65 0 0 1-1.463.889Z"
        fill="currentColor"
      />
    </svg>
  );
}

export function Linkedin(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={26}
      height={26}
      viewBox="0 0 38 37"
      fill="currentColor"
      {...props}
    >
      <path
        d="M19.331 0C9.021 0 .663 8.283.663 18.5S9.02 37 19.33 37C29.641 37 38 28.717 38 18.5S29.642 0 19.331 0zm-5.608 21.672c0 2.079-.005 4.157.009 6.232 0 .284-.082.343-.351.338-1.25-.013-2.5-.013-3.744 0-.251 0-.338-.036-.338-.316.01-4.166.01-8.332 0-12.503 0-.276.069-.353.347-.348 1.236.018 2.476.013 3.712 0 .287 0 .378.059.374.361-.014 2.079-.01 4.158-.01 6.232v.004zm0-9.539c-.484.868-1.542 1.301-2.718 1.116-1.441-.226-2.285-1.559-1.852-2.933.301-.967 1.24-1.572 2.422-1.563 2.17.023 2.89 2.047 2.152 3.38h-.005zm15.545 16.114c-1.25-.023-2.5-.018-3.749 0-.273 0-.323-.072-.323-.33.009-2.205.004-4.415.009-6.62 0-.515-.032-1.026-.187-1.523-.447-1.45-2.039-2.02-3.31-1.175-.657.434-1.09 1.049-1.109 1.853-.032 1.32-.027 2.64-.032 3.959 0 1.165-.009 2.336 0 3.502 0 .244-.032.339-.314.334a153.192 153.192 0 00-3.817 0c-.237 0-.288-.068-.288-.29.01-4.202.06-8.409 0-12.611 0-.194.046-.262.251-.262 1.295.009 2.595.009 3.89 0 .223 0 .283.068.278.28-.014.488 0 .976 0 1.572.1-.126.137-.167.169-.216 1.692-2.603 5.463-2.296 7.118-.8 1.008.913 1.468 2.083 1.669 3.384.082.534.091 1.071.096 1.609 0 2.323 0 4.65.009 6.973 0 .275-.041.375-.351.37l-.01-.009z"
        fill="currentColor"
      />
    </svg>
  );
}

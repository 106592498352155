export function Comicbook(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={68}
      height={62}
      viewBox="0 0 68 62"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M32.931 15.18c-2.858-1.653-4.928-3.862-6.399-6.604.221 11.205-4.323 19.321-21.744 11.753C12 25.71 16.798 41.836 5.78 46.033c9.007-1.674 20.861-1.85 18.643 14.967 4.44-12.146 22.61-12.527 29.743-6.29-4.052-8.675 3.936-19.522 12.54-19.923-12.205-1.543-20.313-10.505-14.764-23.027-2.322 3.38-5.273 5.143-8.365 5.707M56.777 21.885l4.24-4.815M6.346 34.88L1 35.452M60.274 26.156l3.154-1.972M14.816 53.995l2.216-2.042"
        stroke="currentColor"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.175 1.386L47.435 1l-8.846 32.14c-2.455-.47-4.822-1.12-7.155-1.416l2.741-30.338zM29.221 42.183l7.038.918 1.161-5.519-7.272-1.2-.927 5.801z"
        stroke="currentColor"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Menu(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.666 16h12.667M9.666 20.334h12.667M9.666 11.667h12.667"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ArrowUp(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_6108_22128)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.53 14.53a.75.75 0 01-1.06 0L12 11.06l-3.47 3.47a.75.75 0 01-1.06-1.06l4-4a.75.75 0 011.06 0l4 4a.75.75 0 010 1.06z"
          fill="#28302E"
        />
      </g>
      <defs>
        <clipPath id="clip0_6108_22128">
          <path
            fill="currentColor"
            transform="rotate(-180 12 12)"
            d="M0 0H24V24H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

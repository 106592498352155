export function AppStore(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={19}
      height={16}
      viewBox="0 0 19 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.635 9.772h2.613c.187 0 .28.046.373.184.467.78.934 1.607 1.4 2.434H2.035c-.887 0-1.493-.643-1.4-1.516.093-.643.653-1.102 1.307-1.102h2.333c.187 0 .28-.046.373-.184 1.12-1.882 2.24-3.811 3.36-5.694.093-.138.093-.23 0-.413a25.52 25.52 0 01-.887-1.516C6.655 1.093 7.308.036 8.288.082c.42 0 .747.184 1.027.506.093.137.186.275.28.459.14-.092.186-.276.28-.414.326-.413.746-.642 1.26-.55.56.091.933.367 1.073.918.14.413.046.78-.187 1.148l-2.66 4.546c-.513 1.01-1.12 2.02-1.726 3.077z"
        fill="currentColor"
      />
      <path
        d="M16.314 12.39c.28.505.56.964.84 1.47.327.642.234 1.33-.28 1.698-.653.506-1.54.322-2.006-.413l-1.4-2.342-3.08-5.235a.34.34 0 010-.414c.467-.78.933-1.56 1.4-2.388l.14.276c.886 1.515 1.773 3.03 2.66 4.592.093.184.186.23.373.23h2.38c.56 0 1.027.367 1.213.918.187.505 0 1.057-.466 1.424a1.316 1.316 0 01-.84.276c-.234-.092-.56-.092-.934-.092zM5.488 13.4c-.42.643-.7 1.286-1.12 1.883-.373.55-1.166.643-1.726.321-.56-.321-.747-1.056-.514-1.653.187-.413.374-.597.887-.597.793.092 1.633.046 2.473.046z"
        fill="currentColor"
      />
    </svg>
  );
}

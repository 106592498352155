export function TikTok(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={26}
      height={26}
      viewBox="0 0 26 26"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.91.298C5.833.298.094 5.986.094 13s5.74 12.701 12.818 12.701c7.078 0 12.817-5.687 12.817-12.701S19.992.298 12.911.298zm6.66 11.238a4.297 4.297 0 01-3.98-1.899v6.535c0 2.668-2.183 4.83-4.874 4.83-2.692 0-4.874-2.162-4.874-4.83 0-2.667 2.182-4.83 4.874-4.83.102 0 .202.01.301.016v2.382c-.1-.013-.199-.029-.301-.029a2.476 2.476 0 00-2.487 2.464c0 1.36 1.115 2.465 2.487 2.465 1.371 0 2.589-1.074 2.589-2.436l.026-11.101h2.297a4.265 4.265 0 003.945 3.788v2.645h-.004z"
        fill="currentColor"
      />
    </svg>
  );
}

export function Audiobook(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={45}
      height={48}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.8132 2C12.2655 2 2 12.2231 2 24.707V30.5358"
        stroke="currentColor"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.8132 2C37.361 2 47.6265 12.2231 47.6265 24.707V30.5358"
        stroke="currentColor"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.8704 47.3958H42.562C45.3625 47.3958 47.6266 45.1351 47.6266 42.3478V30.5267H38.8704C37.4702 30.5267 36.3336 31.6525 36.3336 33.0507V44.8718C36.3336 46.2609 37.4702 47.3958 38.8704 47.3958Z"
        stroke="currentColor"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.7653 47.3958H7.07371C4.2732 47.3958 2.00916 45.1351 2.00916 42.3478V30.5267H10.7653C12.1655 30.5267 13.3021 31.6525 13.3021 33.0507V44.8718C13.3021 46.2609 12.1655 47.3958 10.7653 47.3958Z"
        stroke="currentColor"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

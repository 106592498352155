export function Ebook2(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      fill="transparent"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.129 8.54a11.52 11.52 0 0 1 1.667-.17c.32-.01.59.243.59.564v6.677a.561.561 0 0 1-.55.563c-1.744.026-3.058.348-4.528 1.212-1.47-.864-2.783-1.186-4.527-1.212a.562.562 0 0 1-.55-.563V8.934c0-.316.26-.575.574-.565 1.732.056 3.04.382 4.503 1.202"
        stroke="#fff"
        strokeWidth={0.615}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.129 14.136V7.239c0-.411-.432-.698-.8-.514-.99.496-1.825 1.506-2.02 2.545v8.116c.223-1.184 1.275-2.33 2.442-2.721a.557.557 0 0 0 .378-.53v0Z"
        stroke="#fff"
        strokeWidth={0.615}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x={0.308}
        y={0.308}
        width={23.385}
        height={23.385}
        rx={11.692}
        stroke="#BBC4C1"
        strokeWidth={0.615}
      />
    </svg>
  );
}

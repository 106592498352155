export function Instagram(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={26}
      height={26}
      viewBox="0 0 37 37"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.24.425C8.167.425 0 8.518 0 18.5s8.167 18.075 18.24 18.075c10.073 0 18.24-8.093 18.24-18.075S28.318.425 18.24.425zm10.693 22.282c0 3.511-2.882 6.367-6.425 6.367h-8.54c-3.544 0-6.426-2.856-6.426-6.367v-8.464c0-3.51 2.882-6.367 6.425-6.367h8.541c3.543 0 6.425 2.856 6.425 6.367v8.464z"
        fill="currentColor"
      />
      <path
        d="M18.331 15.133c-1.86 0-3.374 1.5-3.374 3.344 0 1.844 1.514 3.344 3.374 3.344s3.375-1.5 3.375-3.344c0-1.843-1.514-3.344-3.375-3.344z"
        fill="currentColor"
      />
      <path
        d="M22.513 9.87h-8.541c-2.435 0-4.414 1.96-4.414 4.373v8.464c0 2.413 1.979 4.374 4.414 4.374h8.54c2.436 0 4.415-1.96 4.415-4.374v-8.464c0-2.413-1.98-4.374-4.414-4.374zM18.33 23.691c-2.904 0-5.267-2.34-5.267-5.22 0-2.878 2.363-5.218 5.267-5.218 2.905 0 5.267 2.34 5.267 5.219 0 2.878-2.362 5.22-5.267 5.22zm5.577-9.548a1.248 1.248 0 01-1.254-1.243c0-.687.56-1.242 1.254-1.242.693 0 1.254.555 1.254 1.242 0 .687-.56 1.243-1.254 1.243z"
        fill="currentColor"
      />
    </svg>
  );
}

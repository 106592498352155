export function Ebook(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={47}
      height={52}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M38.9746 10.733C41.4142 10.2893 43.9717 10.0357 46.7831 9.94515C48.2795 9.89082 49.5401 11.0771 49.5401 12.5804V43.7863C49.5401 45.2171 48.3974 46.3944 46.9645 46.4215C38.8023 46.5483 32.6535 48.0516 25.7701 52.0904C18.8866 48.0516 12.7469 46.5483 4.57563 46.4306C3.14271 46.4125 2 45.2262 2 43.7954V12.5894C2 11.1133 3.21526 9.90893 4.68445 9.95421C12.7922 10.2168 18.9138 11.7382 25.761 15.5687"
        stroke="currentColor"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M38.9747 36.8949V4.66565C38.9747 2.74584 36.9523 1.4056 35.2292 2.26589C30.5949 4.58415 26.6861 9.30217 25.7701 14.156V52.0904C26.8131 46.5574 31.7376 41.2054 37.2062 39.3762C38.2673 39.023 38.9747 38.0178 38.9747 36.904V36.8949Z"
        stroke="currentColor"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

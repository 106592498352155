export function Close2(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0651 19.6682C18.5076 20.1107 19.225 20.1107 19.6675 19.6682C20.11 19.2257 20.11 18.5083 19.6675 18.0658L13.6025 12.0009L19.6675 5.93597C20.11 5.49348 20.11 4.77606 19.6675 4.33358C19.225 3.89109 18.5076 3.89109 18.0651 4.33358L12.0002 10.3985L5.93523 4.33358C5.49275 3.89109 4.77533 3.89109 4.33284 4.33358C3.89035 4.77606 3.89035 5.49348 4.33284 5.93597L10.3978 12.0009L4.33284 18.0658C3.89035 18.5083 3.89035 19.2257 4.33284 19.6682C4.77533 20.1107 5.49275 20.1107 5.93523 19.6682L12.0002 13.6033L18.0651 19.6682Z"
        fill="currentColor"
      />
    </svg>
  );
}

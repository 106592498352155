export function Twitter(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={26}
      height={26}
      viewBox="0 0 38 37"
      fill="currentColor"
      {...props}
    >
      <path
        d="M18.968.425C8.894.425.728 8.518.728 18.5s8.166 18.075 18.24 18.075c10.073 0 18.24-8.093 18.24-18.075S29.04.425 18.968.425zm10.16 11.902c0 .05 0 .177-.106.312-.465.62-.966 1.175-1.591 1.64-.091.068-.242.136-.242.267 0 3.254-.839 6.507-3.233 8.866-3.05 3-7.624 3.818-11.687 2.707a11.433 11.433 0 01-2.262-.895c-.237-.131-.474-.262-.702-.416-.06-.045-.1-.09-.05-.122.05-.022.086-.027.269 0 1.135.113 2.307-.054 3.379-.43.592-.202 1.86-.614 2.225-1.17.01-.013.018-.013.027-.026-.146-.037-.296-.037-.442-.06-1.103-.311-2.576-.862-3.26-2.706-.041-.108 0-.163.114-.14.907.072 1.235.023 1.55-.05a2.603 2.603 0 01-.524-.158c-1.391-.524-2.403-1.866-2.59-3.321a2.335 2.335 0 01-.028-.466c.01-.108.055-.14.15-.081.717.366 1.52.452 1.583.438-.178-.135-.342-.289-.502-.438-1.203-1.13-1.673-3.434-.706-4.867.077-.117.132-.117.228-.009 2.202 2.49 4.92 3.651 8.199 4.135.086.013.095 0 .082-.095-.087-.574-.096-1.13.04-1.7a4.139 4.139 0 01.812-1.608c.37-.452.835-.827 1.355-1.098a4.203 4.203 0 011.682-.47 4.04 4.04 0 011.774.285 4.3 4.3 0 011.154.7c.073.059.141.136.21.213.045.045.095.058.164.045a10.407 10.407 0 002.385-.904c.036-.018.063-.027.104-.009.037.027.023.068 0 .104a3.73 3.73 0 01-.665 1.206c-.192.235-.666.814-.99.868.68-.113 1.382-.285 2.011-.57.082-.036.082-.053.082 0v.023z"
        fill="currentColor"
      />
    </svg>
  );
}

export function PlayStore(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={16}
      height={17}
      viewBox="0 0 16 17"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.02 16.75c-.047-.047-.14-.047-.187-.093 0-.046.046-.091.093-.137l7.187-7.348c.093-.092.14-.092.233 0l2.38 2.48c-.047.046-.094.092-.187.092-2.8 1.561-5.553 3.168-8.353 4.73-.233.137-.42.183-.7.275H2.02zM.713 8.391V1.733c0-.322.046-.643.233-.919.093-.138.093-.092.187 0 2.38 2.434 4.713 4.868 7.093 7.302.28.275.28.275 0 .55-2.38 2.435-4.76 4.869-7.14 7.349-.093.091-.14.091-.187 0-.14-.276-.186-.552-.186-.873v-6.75zM1.88.125c.466-.138.886-.046 1.26.184 1.68.964 3.36 1.883 4.993 2.847 1.12.643 2.193 1.24 3.313 1.883.046.046.093.046.186.092-.28.275-.56.55-.793.826-.513.506-1.027 1.057-1.54 1.562-.093.092-.14.092-.233 0L2.999 1.273C2.673.906 2.3.584 1.973.217L1.879.125zM15.786 8.391c0 .551-.234.965-.7 1.24-.794.505-1.634.965-2.474 1.424-.046.046-.093 0-.14-.046-.84-.873-1.726-1.745-2.566-2.618-.047-.046-.047-.092 0-.137.84-.873 1.68-1.7 2.473-2.572.047-.046.093-.046.187 0l2.38 1.378c.56.275.84.734.84 1.331z"
        fill="currentColor"
      />
    </svg>
  );
}

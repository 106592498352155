export function Plus(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={17}
      height={17}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path
        d="M8.5 2.5v12M2.5 8.5h12"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

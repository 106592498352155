export function Book(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={20}
      height={20}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15 16H4"
        stroke="#00823B"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 16V4a3 3 0 0 1 3-3h10a2 2 0 0 1 2 2v9a1 1 0 0 1-1 1H4a3 3 0 0 0-3 3Zm0 0a3 3 0 0 0 3 3h13a2 2 0 0 0 2-2V3"
        stroke="#00825D"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

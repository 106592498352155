export function Store(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={34}
      height={37}
      viewBox="0 0 34 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path transform="translate(1 1)" fill="#fff" d="M0 0H31.5V35H0z" />
      <path
        d="M28.563 36H4.938C2.761 36 1 34.266 1 32.125V12.75c0-2.14 1.762-3.875 3.938-3.875h23.625c2.175 0 3.937 1.734 3.937 3.875v19.375c0 2.14-1.762 3.875-3.938 3.875zM10.625 7.125C10.625 3.742 13.171 1 16.313 1 19.453 1 22 3.742 22 7.125"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Store;

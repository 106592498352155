export function Audiobook2(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.385 15.128v-2.82a5.077 5.077 0 1 0-10.154 0v2.82"
        stroke="#fff"
        strokeWidth={0.615}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.77 16.82h-.282a.846.846 0 0 1-.846-.846v-2.257c0-.467.379-.846.846-.846h.282c.311 0 .564.253.564.564v2.82a.564.564 0 0 1-.564.565Z"
        stroke="#fff"
        strokeWidth={0.615}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.128 16.82h-.282a.564.564 0 0 1-.564-.564v-2.82c0-.312.253-.565.564-.565h.282c.467 0 .847.38.847.846v2.257a.846.846 0 0 1-.847.846Z"
        stroke="#fff"
        strokeWidth={0.62}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x={0.308}
        y={0.308}
        width={23.385}
        height={23.385}
        rx={11.692}
        stroke="#BBC4C1"
        strokeWidth={0.615}
      />
    </svg>
  );
}

export function Club(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={36}
      height={36}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.919 17.61H4.69A3.69 3.69 0 011 13.919V4.69A3.69 3.69 0 014.691 1h9.228a3.69 3.69 0 013.69 3.691v9.228a3.69 3.69 0 01-3.69 3.69zM29.605 32.374H24.07a2.77 2.77 0 01-2.768-2.769V24.07a2.77 2.77 0 012.768-2.768h5.536a2.77 2.77 0 012.769 2.768v5.536a2.77 2.77 0 01-2.769 2.769zM31.45 15.764h-5.536a2.77 2.77 0 01-2.768-2.768V7.459a2.77 2.77 0 012.768-2.768h5.537a2.77 2.77 0 012.768 2.768v5.537a2.77 2.77 0 01-2.768 2.768zM12.996 34.22H7.459a2.77 2.77 0 01-2.768-2.77v-5.536a2.77 2.77 0 012.768-2.768h5.537a2.77 2.77 0 012.768 2.768v5.537a2.77 2.77 0 01-2.768 2.768z"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function Exclamation(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.8002 16.0002C28.8002 19.395 27.4516 22.6507 25.0512 25.0512C22.6507 27.4516 19.395 28.8002 16.0002 28.8002C12.6054 28.8002 9.34969 27.4516 6.94923 25.0512C4.54876 22.6507 3.2002 19.395 3.2002 16.0002C3.2002 12.6054 4.54876 9.34969 6.94923 6.94923C9.34969 4.54876 12.6054 3.2002 16.0002 3.2002C19.395 3.2002 22.6507 4.54876 25.0512 6.94923C27.4516 9.34969 28.8002 12.6054 28.8002 16.0002ZM16.0002 8.0002C16.3185 8.0002 16.6237 8.12662 16.8487 8.35167C17.0738 8.57671 17.2002 8.88194 17.2002 9.2002V16.4002C17.2002 16.7185 17.0738 17.0237 16.8487 17.2487C16.6237 17.4738 16.3185 17.6002 16.0002 17.6002C15.6819 17.6002 15.3767 17.4738 15.1517 17.2487C14.9266 17.0237 14.8002 16.7185 14.8002 16.4002V9.2002C14.8002 8.88194 14.9266 8.57671 15.1517 8.35167C15.3767 8.12662 15.6819 8.0002 16.0002 8.0002ZM16.0002 24.0002C16.4245 24.0002 16.8315 23.8316 17.1316 23.5316C17.4316 23.2315 17.6002 22.8245 17.6002 22.4002C17.6002 21.9758 17.4316 21.5689 17.1316 21.2688C16.8315 20.9688 16.4245 20.8002 16.0002 20.8002C15.5758 20.8002 15.1689 20.9688 14.8688 21.2688C14.5688 21.5689 14.4002 21.9758 14.4002 22.4002C14.4002 22.8245 14.5688 23.2315 14.8688 23.5316C15.1689 23.8316 15.5758 24.0002 16.0002 24.0002Z"
        fill="currentColor"
      />
    </svg>
  );
}

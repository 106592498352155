export function Search(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.369 7.369a5.526 5.526 0 0 1 7.874 7.754.765.765 0 0 0-.12.12 5.526 5.526 0 0 1-7.754-7.874Zm8.317 9.378a7.027 7.027 0 1 1 1.06-1.061l2.784 2.784a.75.75 0 0 1-1.06 1.06l-2.784-2.784Z"
          fill="#28302E"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function Smile(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={24}
      height={24}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path
        d="M21 12c0-4.962-4.038-9-9-9-4.963 0-9 4.037-9 9M12 21c4.963 0 9-4.038 9-9M3 12c0 4.963 4.037 9 9 9"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.328 9.52a.25.25 0 1 0 .002.5.25.25 0 0 0-.002-.5Z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5 9.5c0 .276.114 1 1 1 .276 0 .5-.724.5-1s-.724-.5-1-.5a.5.5 0 0 0-.5.5Z"
        fill="currentColor"
      />
      <path
        d="M8.639 9.5a.25.25 0 1 0 .002.5.25.25 0 0 0-.002-.5ZM8.5 16s1.313-1.312 3.5-1.312c2.188 0 3.5 1.312 3.5 1.312"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 9.5c0 .276-.114 1-1 1-.276 0-.5-.724-.5-1a.5.5 0 0 1 .5-.5c.276 0 1 .224 1 .5Z"
        fill="currentColor"
      />
    </svg>
  );
}

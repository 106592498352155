export function Comeeks(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={36}
      height={38}
      viewBox="0 0 36 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.162 1.104l4.765 5.998c3.897-3.662 7.424-2.84 10.636.717l5.502-4.804c.211-.186.553.027.448.318l-2.159 6.264c4.95 1.167 6.476 6.263 2.975 10.43l4.396 3.954c.185.186.08.504-.184.504l-5.423-.16c.974 2.947-.948 6.184-4.686 6.317l.737 5.467c.026.266-.29.425-.474.24l-3.422-3.77c-4.58 4.671-11.426 3.185-12.584-2.229L1.37 32.951c-.263.08-.474-.212-.316-.451l4.66-6.104c-5.87-1.54-5.107-6.714-2.448-9.554-3.58-4.406-.658-8.838 4.58-7.882l-.184-7.643c0-.292.342-.425.5-.213z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeMiterlimit={10}
      />
      <path
        d="M10.662 26.766v-4.504a.1.1 0 01.183-.056l1.991 2.95 1.992-2.95a.1.1 0 01.182.056v4.504m2.174 0v-1.61m2.9 1.61v-1.61m-2.9 0v-1.772c0-.8.65-1.449 1.45-1.449v0c.8 0 1.45.649 1.45 1.45v1.77m-2.9 0h2.9m2.173 1.611l2.899-4.83m-2.899 0l2.899 4.83m-14.494-8.052v-3.22m0-3.221v3.22m4.348 3.221v-3.22m0-3.221v3.22m0 0h-4.348m8.857.806l3.221 3.22m-.483-6.246v4.441a1 1 0 01-1 1h-3.073a1 1 0 01-1-1v-4.441a1 1 0 011-1h3.073a1 1 0 011 1z"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
    </svg>
  );
}

export function Minibook(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width={45}
      height={60}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 16.1493H40.6552C43.7062 16.1493 46.1774 18.6823 46.1774 21.8097V52.9419C46.1774 56.0692 43.7062 58.6023 40.6552 58.6023H7.52217C4.47117 58.6023 2 56.0692 2 52.9419V16.2936C2 13.7945 3.59867 11.5926 5.93179 10.8738L34.3378 2.12279C36.1105 1.57373 37.8941 2.93506 37.8941 4.83129V16.1493"
        stroke="currentColor"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.84052 52.3879V44.025L13.4158 49.6002L18.991 44.025V52.3879M24.5662 52.3879V44.025M30.1415 52.3879V44.025L35.7167 52.3879V44.025M41.2919 52.3879V44.025"
        stroke="currentColor"
        strokeWidth={2.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
